import { useState, useEffect } from 'react';
import { createContainer } from 'unstated-next';
import useSWR from 'swr';

import { IResponseError, getTokenFromCookie } from '@/lib/apiBase';
import { IConfig, fetchConfig, IConfigResponse } from '@/lib/configApi';

const useConfig = (initialData?: IConfigResponse) => {
  const serverConfig = initialData?.config;
  const [config, setConfig] = useState<IConfig | undefined>(serverConfig);
  const [error, setError] = useState<IResponseError | undefined>();
  const token = getTokenFromCookie();

  const {
    data,
    error: requestError,
    isValidating,
  } = useSWR(`config-${token}`, () => fetchConfig(token), {
    // revalidateOnFocus: false,
    // revalidateOnMount: false,
    // revalidateOnReconnect: false,
    fallbackData: initialData,
  });

  useEffect(() => {
    if (data) {
      setConfig(data.config);
      setError(requestError as IResponseError);
    }
  }, [data, requestError]);

  const loading: boolean = isValidating && !config;

  return { loading, config, error };
};

export const Config = createContainer(useConfig);

export default useConfig;
