'use client';
import { useState, useEffect } from 'react';
import Link from 'next/link';
import * as Icon from '@/ui/Icon';
import * as Styled from './styled';
import Hamburger from 'hamburger-react';
import { UserAvatar } from '@/components/Avatar';
import { usePathname } from 'next/navigation';

import { IUser } from '@/types';

interface MobileMenuProps {
  user?: IUser;
  onLogout?: () => void;
}

const LoggedInUserOptions: React.FC<{ user: IUser; onLogout?: () => void }> = ({ user, onLogout }) => {
  // const { logout } = Identity.useContainer();
  return (
    <>
      <li>
        <Link href="/">
          <Styled.NavigationItem>
            <UserAvatar user={user} size={20} />
            <span>My profile</span>
          </Styled.NavigationItem>
        </Link>
      </li>
      <li onClick={onLogout}>
        <Styled.NavigationItem>
          <Icon.X />
          <span>Sign out</span>
        </Styled.NavigationItem>
      </li>
    </>
  );
};

const LoggedOutUserOptions: React.FC<{
  user?: IUser;
}> = ({ user }) => {
  const pathmane = usePathname();
  const loginPath = `/login?redirect=${encodeURIComponent(pathmane)}`;
  const registerPath = `/register?redirect=${encodeURIComponent(pathmane)}`;
  return (
    <>
      <li>
        <Link href={loginPath}>
          <Styled.NavigationItem>
            <Icon.User />
            <span>Log in</span>
          </Styled.NavigationItem>
        </Link>
      </li>
      <li>
        <Link href={registerPath}>
          <Styled.NavigationItem>
            <Icon.Add />
            <span>Get Started</span>
          </Styled.NavigationItem>
        </Link>
      </li>
    </>
  );
};

const MobileMenu: React.FC<MobileMenuProps> = ({ user, onLogout }) => {
  const externalDocsLink = 'https://docs.oxen.ai';
  const externalFaqLink = 'https://oxenai.notion.site/Oxen-FAQs-0b7ce10f5c794ab69f88a916bcab5e06';
  const pathname = usePathname();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  return (
    <>
      <Styled.MobileMenuWapper>
        <Hamburger
          size={25}
          toggled={isOpen}
          toggle={() => {
            setIsOpen(!isOpen);
          }}
        />
        <Styled.MobileMenu open={isOpen}>
          <ul>
            <li>
              <Link href="/explore">
                <Styled.NavigationItem>
                  <Icon.Compass />
                  <span>Explore</span>
                </Styled.NavigationItem>
              </Link>
            </li>
            <li>
              <Link href="/community">
                <Styled.NavigationItem>
                  <Icon.Users width={14} />
                  <span>Community</span>
                </Styled.NavigationItem>
              </Link>
            </li>
            <li>
              <a target="_blank" href={externalDocsLink} rel="noopener noreferrer">
                <Styled.NavigationItem>
                  <Icon.InfoCircle />
                  <span>Docs</span>
                </Styled.NavigationItem>
              </a>
            </li>
            <li>
              <a target="_blank" href={externalFaqLink} rel="noopener noreferrer">
                <Styled.NavigationItem>
                  <Icon.QuestionCircle />
                  <span>FAQ</span>
                </Styled.NavigationItem>
              </a>
            </li>
            <li>
              <Link href="/blog">
                <Styled.NavigationItem>
                  <Icon.Book />
                  <span>Blog</span>
                </Styled.NavigationItem>
              </Link>
            </li>
            <li>
              <Link href="/billing/plans">
                <Styled.NavigationItem>
                  <Icon.CreditCard width={14} />
                  <div>{user ? 'Upgrade' : 'Pricing'}</div>
                </Styled.NavigationItem>
              </Link>
            </li>
            {user ? <LoggedInUserOptions user={user} onLogout={onLogout} /> : <LoggedOutUserOptions user={user} />}
          </ul>
        </Styled.MobileMenu>
      </Styled.MobileMenuWapper>
    </>
  );
};

export default MobileMenu;
