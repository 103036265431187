import React from 'react';

import Button from '@/ui/Button';
import Link from 'next/link';

const CompleteAccountButton = ({}) => {
  return (
    <Link href="/register/complete">
      <Button>Complete Your Profile</Button>
    </Link>
  );
};

export default CompleteAccountButton;
