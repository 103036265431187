'use client';
import { Component, ElementType, Fragment } from 'react';
import Link from 'next/link';
import * as Icon from '@/ui/Icon';
import { Menu } from '@headlessui/react';

import * as Styled from './styled';

const PopoverMenuItemGroup: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className="menu-item-group">{children}</div>;
};

const PopoverMenuItem: React.FC<{ children: React.ReactNode } & React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...rest
}) => {
  return (
    <Menu.Item as="div" className="menu-item" {...rest}>
      {children}
    </Menu.Item>
  );
};

const PopoverMenuButton: React.FC<{ children: React.ReactNode; as?: ElementType | undefined }> = ({
  children,
  as = 'div',
  ...rest
}) => (
  <Menu.Button as={as} style={{ position: 'relative' }}>
    {children}
  </Menu.Button>
);

const PopoverMenu: React.FC<{
  button: React.ReactNode;
  children: React.ReactNode;
  justify?: 'right' | 'left';
  offsetX?: string;
  offsetY?: string;
}> = ({ button, children, justify = 'left', offsetX = '0', offsetY = '0' }) => {
  return (
    <Styled.PopoverMenu $justify={justify} $offsetX={offsetX} $offsetY={offsetY}>
      <Menu>
        {button}
        {/* <Menu.Button as="div">
          <Icon.ArrowRight />
          <span>Options</span>
        </Menu.Button> */}
        <Menu.Items className="menu-items">
          {children}
          {/* {links.map((link) => (
            <Menu.Item key={link.href} as="div" className="menu-item">
              {({ active }) => (
                <a href={link.href} className={`${active ? 'bg-blue-500 text-white' : 'bg-white text-black'}`}>
                  {link.label}
                </a>
              )}
            </Menu.Item>
          ))} */}
        </Menu.Items>
      </Menu>
    </Styled.PopoverMenu>
  );
};

// export default PopoverMenu;
let PMenu = Object.assign(PopoverMenu, {
  Button: PopoverMenuButton,
  Group: PopoverMenuItemGroup,
  Item: PopoverMenuItem,
});

export default PMenu;
