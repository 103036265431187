'use client';
import React from 'react';
import styles from './index.module.css';
import Link from 'next/link';
import OxenLogo, { OxenAiLogo } from '@/ui/OxenLogo';
import classNames from 'classnames';

import { Config } from '@/hooks/useConfig';
import { IConfig } from '@/lib/configApi';
import { Identity } from '@/hooks/useIdentity';

import UserContext from '../UserContext';
import Navigation from '../Navigation';
import MobileMenu from '../MobileMenu';
import { Row } from '@/ui/primatives';

import ModalSearch from '../ModalSearch';
import * as Styled from './styled';
import { IUser } from '@/types';

export type HeaderProps = React.HTMLProps<HTMLDivElement> & {
  children?: React.ReactNode;
  currentUser?: IUser;
  noUserContent?: boolean;
  variant?: 'default' | 'marketing';
  registrationDisabled?: boolean;
  onLogout?: () => void;
  config?: IConfig;
  sticky?: boolean;
};

type BaseHeaderProps = HeaderProps & {
  contextPath?: React.ReactNode;
};

export const BaseHeader: React.FC<BaseHeaderProps> = ({
  children,
  currentUser,
  contextPath,
  variant,
  noUserContent,
  registrationDisabled,
  onLogout,
  sticky,
  config,
  ...rest
}) => {
  const headerClass = classNames(styles.header, {
    [styles.sticky]: sticky,
  });
  return (
    <header className={headerClass} {...rest}>
      <Styled.GlobalBar>
        <Styled.LeftHeaderContent>
          <MobileMenu user={currentUser} onLogout={onLogout} />
          <Link href="/">
            <Styled.Logo>
              <OxenAiLogo height={28} className="logo-full" />
              <OxenLogo height={28} className="logo-short" />
            </Styled.Logo>
          </Link>
          <Styled.HeaderPath>{contextPath}</Styled.HeaderPath>
        </Styled.LeftHeaderContent>

        <Row gap={50} justifyContent="flex-end" alignItems="center" className={styles.contextMenu}>
          <Navigation currentUser={currentUser} config={config} />
          <Row gap={20} alignItems="center" justifyContent="flex-end" style={{ width: 'auto', margin: 0 }}>
            <ModalSearch />
            <div>{!noUserContent && <UserContext variant={variant} user={currentUser} onLogout={onLogout} />}</div>
          </Row>
        </Row>
      </Styled.GlobalBar>
      {children && <Styled.LocalBar>{children}</Styled.LocalBar>}
    </header>
  );
};

const WrappedBaseHeader: React.FC<BaseHeaderProps> = ({
  children,
  currentUser,
  contextPath,
  variant,
  noUserContent,
  registrationDisabled,
  sticky,
  ...rest
}) => {
  const { logout } = Identity.useContainer();
  const { config } = Config.useContainer();
  return (
    <div className={styles.wrapper}>
      <BaseHeader
        currentUser={currentUser}
        contextPath={contextPath}
        variant={variant}
        noUserContent={noUserContent}
        registrationDisabled={registrationDisabled}
        onLogout={logout}
        config={config}
        sticky={sticky}
        {...rest}
      >
        {children}
      </BaseHeader>
    </div>
  );
};

export default WrappedBaseHeader;
