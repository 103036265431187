'use client';
import Link from 'next/link';
import { IUser } from '@/types';

import { usePathname } from 'next/navigation';

import Menu from '@/ui/PopoverMenu';

import { UserAvatar } from '@/components/Avatar';
import Button from '@/ui/Button';
import * as Icon from '@/ui/Icon';

import * as Styled from './styled';
import CompleteAccountButton from '@/components/CompleteAccount/CompleteAccountButton';

const CreateMenu: React.FC<{}> = ({}) => (
  <Menu
    offsetY="1em"
    button={
      <Menu.Button>
        <Button.Basic inline>
          <Icon.Add />
          <Icon.DownSmall width={12} />
        </Button.Basic>
      </Menu.Button>
    }
  >
    <Menu.Group>
      <Menu.Item>
        <Link href="/repositories/new">New repository</Link>
      </Menu.Item>
    </Menu.Group>
    <Menu.Group>
      <Menu.Item>
        <Link href="/account/organizations/new">New organization</Link>
      </Menu.Item>
    </Menu.Group>
  </Menu>
);

const NavigateMenu: React.FC<{ user: IUser; onLogout?: () => void }> = ({ user, onLogout }) => {
  return (
    <Styled.NavigateMenu>
      <Menu
        justify="right"
        offsetX="-1em"
        offsetY="1em"
        button={
          <Menu.Button>
            <div style={{ cursor: 'pointer', display: 'flex', gap: 5 }}>
              {/* <UserAvatar user={user} size={20} /> */}@{user?.username}
              <Icon.DownSmall width={12} />
            </div>
          </Menu.Button>
        }
      >
        <Menu.Group>
          <Menu.Item style={{ paddingBottom: 20 }}>
            Signed in as <b>{user.username}</b>
          </Menu.Item>
        </Menu.Group>
        <Menu.Group>
          <Menu.Item>
            <Link href="/settings/profile">My profile</Link>
          </Menu.Item>
          <Menu.Item>
            <Link href="/">My repositories</Link>
          </Menu.Item>
          <Menu.Item>
            <Link href="/settings/organizations">My organizations</Link>
          </Menu.Item>
          <Menu.Item>
            <Link href={`/collections/${user.username}`}>My collections</Link>
          </Menu.Item>
          <Menu.Item>
            <Link href="/starred">My stars</Link>
          </Menu.Item>
          <Menu.Item>
            <div style={{ cursor: 'pointer' }} onClick={onLogout}>
              Sign out
            </div>
          </Menu.Item>
        </Menu.Group>
      </Menu>
    </Styled.NavigateMenu>
  );
};

const UserContext: React.FC<{
  user?: IUser;
  variant?: 'default' | 'marketing';
  onLogout?: () => void;
}> = ({ user, variant = 'marketing', onLogout }) => {
  const pathmane = usePathname();
  const loginPath = `/login?redirect=${encodeURIComponent(pathmane)}`;
  const registerPath = `/register?redirect=${encodeURIComponent(pathmane)}`;

  return user?.id ? (
    user.status === 'incomplete' ? (
      <Styled.UserInfo>
        <CompleteAccountButton />
        <Button.Text onClick={onLogout}>Log out</Button.Text>
      </Styled.UserInfo>
    ) : (
      <Styled.UserInfo>
        <CreateMenu />
        <NavigateMenu user={user} onLogout={onLogout} />
      </Styled.UserInfo>
    )
  ) : (
    <Styled.SignInSignUp>
      <Button.Text href={loginPath} testId="sign-in">
        LOG IN
      </Button.Text>

      <Button.Black href={registerPath} testId="create-account">
        SIGN UP
      </Button.Black>
    </Styled.SignInSignUp>
  );
};

export default UserContext;
