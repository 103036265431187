import styled from 'styled-components';
// import { colors } from '@/styles';

export const PopoverMenu = styled.div<{ $justify: 'right' | 'left'; $offsetX: string; $offsetY: string }>`
  /* border: 1px solid lime; */
  position: relative;

  .menu-items {
    background-color: var(--color-primary-white);
    position: absolute !important;
    top: 100%;
    margin-top: ${(props) => props.$offsetY || '0px'};
    ${(props) => (props.$justify === 'right' ? `right: ${props.$offsetX};` : `left: ${props.$offsetX};`)}
    /* right: 1px; */
    border: 1px solid var(--color-secondary-lightGray2);
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 5px;

    min-width: 100px;

    .menu-item-group {
      :not(:last-child) {
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid var(--color-secondary-lightGray2);
      }
    }

    .menu-item {
      /* border: 1px dashed blue; */
      padding: 5px 10px;
      &:hover {
        border-radius: 5px;
        background-color: var(--color-secondary-lightGray2);
      }
      :not(:last-child) {
        /* border-bottom: 1px solid var(--color-secondary-lightGray2); */
      }
    }
  }
  /* display: flex;
  align-items: center;
  flex: 0;
  gap: 50px;
  & > a {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    white-space: nowrap;
    gap: 10px;
    span {
      margin-bottom: -3px;
    }
  } */
`;
