'use client';
import { useState, useEffect, useRef, MutableRefObject } from 'react';
import { createPortal } from 'react-dom';
import styles from './modal.module.css';
import * as Icon from '@/ui/Icon';
import { MainSearch } from '@/components/Search';

const Modal: React.FC<{ onClose: Function }> = ({
  onClose = () => {
    console.log('close');
  },
}) => {
  const ref: MutableRefObject<any> = useRef();

  useEffect(() => {
    const closeOnClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('click', closeOnClickOutside);
    return () => {
      document.removeEventListener('click', closeOnClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return createPortal(
    <div className={styles.modal}>
      <div className={styles.modalContent} ref={ref}>
        <MainSearch placeholder="Search for data repositories" instanceId="modalHeaderSearch" autoFocus />
      </div>
    </div>,
    document.body,
  );
};

const ModalSearch: React.FC<{}> = ({}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div
        className={styles.dummySearchWrapper}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <div className={styles.dummySearchBlocker} />
        <div className={styles.dummySearchBar}>
          <MainSearch placeholder="Search" instanceId="headerSearch" />
        </div>
        {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}
      </div>

      <div
        className={styles.mobileSearch}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <Icon.Search />
      </div>
    </>
  );
};

export default ModalSearch;
