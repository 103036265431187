import styled from 'styled-components';
import { breakPoints } from '@/styles';

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  & > div {
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  @media (max-width: ${breakPoints.tablet}) {
    display: none;
  }
`;

export const SignInSignUp = styled.div`
  white-space: nowrap;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  @media (max-width: ${breakPoints.tablet}) {
    display: none;
  }
  button {
    padding: 10px 20px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
`;

export const NavigateMenu = styled.div`
  .menu-item {
    width: 100%;
    a {
      width: 100%;
      display: block;
    }
  }
`;
