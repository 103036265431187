'use client';
import styled from 'styled-components';
import { breakPoints } from '@/styles';

export const HeaderPath = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  .path {
    font-size: 16px;
    font-weight: 500;
  }
  .separator {
    color: var(--color-semantic-disabled);
  }
  @media (max-width: ${breakPoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const GlobalBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-style: solid;
  border-width: 3px 0 0px 0;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    90deg,
    #f87a7a 0%,
    #f9947d 20%,
    #f57ab1 40%,
    #e07de8 60%,
    #dbb2ed 80%,
    #efb4c9 100%
  );
  padding: 20px 25px;
  gap: 25px;

  @media (max-width: ${breakPoints.tablet}) {
    padding: 10px 5px;
  }
`;

export const LeftHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: ${breakPoints.tablet}) {
    gap: 15px;
  }
`;

export const Logo = styled.span`
  & > .logo-short {
    display: none;
    /* display: none; */
  }
  & > .logo-full {
    display: inline;
  }
  @media (max-width: ${breakPoints.tabletPortrait}) {
    & > .logo-short {
      display: inline;
    }
    & > .logo-full {
      display: none;
    }
  }
`;

export const LocalBar = styled.div`
  display: flex;
  padding: 10px 25px 0px;

  @media (max-width: ${breakPoints.mobile}) {
    padding: 10px 10px 0px;
  }
`;
