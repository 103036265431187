import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const Navigation = styled.div`
  display: flex;
  align-items: center;
  flex: 0;
  gap: 40px;
  & > a {
    display: flex;
    align-items: center;
    /* text-transform: uppercase; */
    white-space: nowrap;
    gap: 10px;
    span {
      margin-bottom: -3px;
    }
  }
  svg {
    display: none;
    /* display: none; */
  }

  @media (min-width: ${breakPoints.largeDesktop}) {
    svg {
      display: inherit;
    }
  }

  @media (max-width: ${breakPoints.tabletPortrait}) {
    gap: 20px;
  }

  @media (max-width: ${breakPoints.tablet}) {
    display: none;
  }

  @media (max-width: ${breakPoints.mobile}) {
    display: none;
  }
`;

export const NavigationItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
